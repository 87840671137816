export const environment = {
  production: false,
  hmr: false,
  version: 'v6.4 December 2022',
  apiBaseUrl: 'https://api.wajooba.me',
  googleClientId: '78050459183-0ehcavsrodmt2c11o6b8d18vq15ukoo8.apps.googleusercontent.com',
  fbClientId: '686303805415978',
  awsS3Folder: 'staging/post/',
  tenatAuthInfoDomain: 'wajooba-stg.auth0.com',
  tenatAuthInfoClientId: 'orF9Ycw2DyC1Y4S4L1nxNVARMuxRPkyK',
  recaptchaSiteKey: '6LeW-BgpAAAAABg1DxexeLELVqFS5ATYzqcfSsYZ',
};
